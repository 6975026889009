#hambergar:hover {
  background-color: white !important;
}
.w3-button {
  /* background-color:blueviolet; */
  color: white;
  /* position:fixed */
  margin: auto;
  right: 0;
  top: 20px;
  /* right:/20px; */
  padding: 0.25rem;
  transition: transform 0.3s ease-in-out;
  /* background: linear-gradient(to right, #ff416c, #ff4b2b); */
}
.w3-button.active {
  transform: translateX(-100px);
}

.w3-sidebar {
  width: 70%;
  max-width: 300px;
  height: 100%;
  background-color: #fff;
  color: black !important;
  position: fixed;
  top: 0;
  left: -500px;
  height: 100vh;
  transition: all 0.5s;
  z-index: 99999999 !important;
}
.w3-container {
  padding: 0px;
  top: 0 !important;
  margin: auto;
  background: #fff !important;
}
.w3-teal {
  background: white !important;
}
.w3-bar-block .w3-bar-item {
  background-color: #fff;

  /* padding: 20px; */
  height: 60px;
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  color: #000 !important;
}


.w3-bar-block .w3-bar-item:hover {
  background: #cbbcbc !important;
  color: #000 !important;
}

.icon {
  /* padding: 10px; */
  border-radius: 8px;
}
.w3-bar-block .w3-bar-item:hover .icon {
  /* background-color: #e7e7e7; */
  /* padding: 10px; */
  border-radius: 8px;
}
.arrow {
  position: absolute;
  right: 25px;
}
.arrow > img {
  height: 11px;
  width: 6px;
  max-width: 100%;
}

.icon {
  overflow: hidden;
  position: relative;
}
.icon > img {
  height: 26px;
    /* width: 26px; */
    /* max-width: 100%; */
    margin-right: 5px;
}
#sidebarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  display: none;
  transition: all 0.5s ease-in-out 0s;
}

/* ===============new css=============== */
.sidebar_link {
  border-bottom: 1px solid #003441 !important;
}
.sidebar_link h3 {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  /* margin-left: 12px;
  margin-bottom: 0; */
}

.alrt_msg_div {
  background-color: #080b1c;
  padding: 1rem;
  margin-bottom: 10px;
  border: 1px solid #003441;
  border-radius: 0.375rem;
  margin-top: 10px;
}
.alrt_msg_div h5 {
  font-size: 0.7rem;
  line-height: 16px;
  text-align: left;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
}
.alrt_msg_div span {
  font-size: 1.2rem;
  line-height: 16px;
  text-align: left;
  color: #003441;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
}

.alrt_msg_div a {
  font-size: 1rem !important;
  line-height: 16px;
  text-align: left;
  color: #f5f5f5 !important;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
}
body {
  position: relative !important;
}
.bottom_menus_main {
  position: fixed !important;
  bottom: 0px !important;
  max-width: 480px;
  width: 100%;
  margin: auto !important;
  /* background-color: rgb(0, 0, 0); */

  z-index: 999999999999;
}
.main_bottom_menus {
  max-width: 100%;
  margin: auto !important;
  background-color: #0c131d;
  border-radius: 0px;
  padding: 15px 10px;
}
.bottom_link_main {
  display: flex;
  justify-content: space-between;
}
.bottom_link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
.bottom_link span {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  display: none;
  transition: 0s all ease-in-out;
}
.active_link {
  transition: 0s all ease-in-out;
}
.active_link span,
.active_link .sidebar_link_icon {
  color: rgb(255, 255, 255) !important;
  transition: 0s all ease-in-out;
}
.active_link span {
  margin-left: 8px;
}
.active_link {
  background-color: rgb(198, 198, 198);
  background-color: rgb(4 38 62);
  padding: 5px 8px;
  border-radius: 20px;
  transition: 0s all ease-in-out;
}
.active_link span {
  display: block;
  color: #fff !important;
  transition: 0s all ease-in-out;
}
.active_link span .sidebar_link_icon {
  color: #fff !important;
}
.sidebar_link_icon {
  color: #fff !important;
  font-size: 22px;
}
.active_link .sidebar_link_icon {
  font-size: 20px;
}

@media screen and (max-width: 480px) {
  .bottom_link span {
    font-size: 12px;
  }
  .main_bottom_menus {
    padding: 10px 8px;
  }
  .sidebar_link_icon {
    font-size: 18px;
  }
}

/* ============Game_Tournaments_box_main_section============== */
.Game_Tournaments_box_main_section {
  padding: 0 15px;
}
.mb_space {
  padding-bottom: 100px;
}

/* ============top menu bar============== */

.top_menu_bar_main .icons {
  font-size: 20px;
  color: #4e4e4e;
}

.top_menus_link {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_menu_bar_main .active_link .icons {
  color: #fff;
  transition: 0s all;
}

.top_menus_btns {
  width: auto;
  height: auto;
  /* margin-right: 10px; */
  /* background-color: rgb(0 52 65); */
  padding: 5px 8px;
  border-radius: 20px;
  transition: 0s all ease-in-out;
}

.top_menus_btns .icons {
  color: #4e4e4e;
  font-size: 18px;
  transition: 0s all ease-in-out;
}

.top_menus_link span {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin: 0 4px 0 4px;
  transition: 0s all ease-in;
}

.top_menu_bar_main .active_link {
  /* background-color: red; */
  padding: 0;
}

.top_menu_bar_main .notification_btn.active_link {
  border: 1px solid #fff;
}

.notification_btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4e4e4e;
  border-radius: 50%;
  margin-left: 15px;
  margin-top: 15px;
  /* background-color: green; */
}
.sidebar_header {
  background-color: #000 !important;
  padding: 20px;
}

.sidebar_header h2 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.wallet_balance_btn {
  border: 1px solid #000;
  width: auto;
  height: auto;
  padding: 3px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d9596;
  border-radius: 20px;
}
.wallet_balance_btn .icons {
  color: #fff;
}

.wallet_balance_btn span {
  color: #fff;
  margin-left: 5px;
}
.wallet_balance_btn_flex {
  display: flex;
  flex-direction: column;
}
.header_navlink_main h5 {
  color: #fe3347;
  font-size: 0.7rem;
  margin-bottom: 2px;
  text-align: center;
}
.custom_select_box select {
  border: none !important;
  background-color: transparent !important;
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  margin-left: 12px !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important;
}
.custom_select_box option,
.custom_select_box label {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  margin-left: 12px;
  margin-bottom: 0;
}
.dropdown .dropdown-menu .dropdown-item a {
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;

  margin-bottom: 0;
  color: #444 !important;
}
.custom_select_box:hover,
.custom_select_box:focus {
  border: none !important;
  background-color: transparent !important;
}
.btn-primary:not(.btn-light):not(.btn-secondary):focus {
  box-shadow: none !important;
}
.w3-bar-block .w3-bar-item:hover .custom_select_box select {
  background: #003441 !important;
  color: #fff !important;
}

.custom_dropdown {
  border: none !important;
  background-color: transparent !important;
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important;
}
.dropdown_link {
  margin-top: 30px;
}
.dropdown_link li {
  margin-left: 20px;
}
.dropdown_link a {
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important;
  color: #003441 !important;
}

.custom_dropdown .btn {
  border: none !important;
  background-color: transparent !important;
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;

  margin-bottom: 0 !important;
  font-weight: 500 !important;
}

.w3-bar-block .w3-bar-item:hover .custom_dropdown.btn {
  background: #003441 !important;
  color: #fff !important;
}

.custom_dropdown {
  /* overflow: inherit; */

  position: unset !important;
  margin-top: 20px;
}

.custom-swal-popup {
  z-index: 9999 !important;
}
iframe{
  z-index: 9!important;
}

.app-download{
  padding: 2px 5px;
  border-bottom: 0;
  border: 1px solid #29c6cc;
  border-radius: 4px;
  /* margin-right: 5px;
  margin-left: 10px; */
}
.app-download .icon{
  padding: 0;
 
}
.app-download span{
  color:  #29c6cc;
  font-size: 12px;
  margin-left: 3px;
}

.app-download img{
  filter: brightness(22.2);
}
.app-download:hover{
  background-color: transparent!important;
}